<template>
  <div class="header-options">
    <div v-for="group in groupsProcessed" v-bind:key="group.label">
      <labelc type="groupheader bold" :text="group.label"/>
      <labelc v-for="item in group.processedItems" :disabled="item.disabled" v-bind:key="item.text" type="block border-bottom" :text="item.text" :icon-left="item.icon != undefined? {icon: item.icon} : null" @click="ItemClicked(item)" />
    </div>
    <labelc v-for="item in itemsProcessed" :disabled="item.disabled" v-bind:key="item.text" type="block border-bottom" :text="item.text" :icon-left="item.icon != undefined? {icon: item.icon, styleIcon: item.styleIcon} : null" @click="ItemClicked(item)" />
  </div>
</template>

<script>
export default {
  name: "burgermenu",
  data(){
    return{
      pictureUrl: '',
      name: '',
      groups: [],
      items: [],
    }
  },
  computed:{
    groupsProcessed(){
      let pgroups = [];
      this.groups.forEach(group => {
        let items = group.items.filter(b => !b.hasOwnProperty('show') || b.show);
        group.processedItems = items;
        pgroups.push(group);
      })

      return pgroups;
    },
    itemsProcessed() {
      return this.items.filter(b => !b.hasOwnProperty('show') || b.show);
    }
  },
  watch: {
    state: {
      immediate: true,
      handler(newVal, oldVal) {
        for (let x in newVal) this[x] = newVal[x];
      }
    }
  },
  props:{
    state: Object,
  },
  mounted(){
    document.addEventListener('keydown', this.NavigateList);
  },
  destroyed(){
    document.removeEventListener('keydown', this.NavigateList);
  },
  methods:{
    NavigateList($event){
      let direction = 0;
      if($event.key === 'ArrowLeft' || $event.key === 'ArrowUp') direction = -1;
      if($event.key === 'ArrowRight' || $event.key === 'ArrowDown') direction = +1;
      if(direction === 0) return;
      let list = this.$el.querySelectorAll('.label input');
      list = Array.apply(null, list);
      if(list.length === 0) return;
      let selected = list.find(l => l.parentNode.classList.contains('focus-highlight'));
      if(!selected){
        if(list.length > 0) {
          let input = list[0].querySelector('input')
          if(input) input.select();
        }
      } else {
        let index = list.indexOf(selected);
        list[(list.length + (index + direction)) % list.length].select();
      }
    },
    ItemClicked(item){
      if(item.click != undefined){
        item.click();
      }
      if(item.closeOnClick){
        this.Close();
      }
    },
    Close(){
      this.$parent.Toggle();
    },
  }
}
</script>

<style scoped>
.header-options{
  width: 280px;
}
</style>
